const axios = require('axios');
const { chatServiceApiServerlessUrl, chatServiceUrl, buildAuthHeaders } = require('./settings.js');

exports.getChannelListForDropdown = function (params) {
  return axios.post(chatServiceUrl + `/channel/list-for-dropdown`, params, {
    headers: buildAuthHeaders()
  });
};

exports.getInstalledChannels = function () {
  return axios.get(chatServiceApiServerlessUrl + `/settings/messaging-channels`, {
    headers: buildAuthHeaders()
  });
};

exports.deleteChannel = function (params) {
  return axios.post(chatServiceApiServerlessUrl + `/settings/messaging-channels/delete`, params, {
    headers: buildAuthHeaders()
  });
};

exports.instagram = {
  handleInstagramOAuthResponse: function (params) {
    return axios.get(chatServiceApiServerlessUrl + `/settings/instagram/auth-redirect`, {
      params: params,
      headers: buildAuthHeaders()
    });
  },

  saveInstagramChannels: function (params) {
    return axios.post(chatServiceApiServerlessUrl + `/settings/instagram/auth-response/save`, params, {
      headers: buildAuthHeaders()
    });
  }
};

exports.fbMessenger = {
  handleOAuthResponse: function (params) {
    return axios.get(chatServiceApiServerlessUrl + `/settings/fb-messenger/auth-redirect`, {
      params: params,
      headers: buildAuthHeaders()
    });
  },

  saveChannel: function (params) {
    return axios.post(chatServiceApiServerlessUrl + `/settings/fb-messenger/auth-response/save`, params, {
      headers: buildAuthHeaders()
    });
  }
};

exports.whatsApp = {
  integrateWhatsAppCloudApiChannel: function (params) {
    return axios.post(chatServiceUrl + `/channel/setup/whatsApp/whatsapp-cloud-api`, params, {
      headers: buildAuthHeaders()
    });
  }
};
