<template>
  <el-dialog title="Add Instagram Inbox Channel" :visible.sync="popupVisible" width="800px">
    <!-- Loader -->
    <div v-if="inProgress" class="w-full rounded-md border bg-white overflow-hidden" v-loading="true" style="height: 90vh"></div>

    <!-- Start Integration -->
    <div v-if="!isSuccessMode">
      <div class="text-xs text-gray-500 text-center w-full mt-5 mb-6">To connect your Instagram account, you will need to install "Growlytics Messaging Connector" app on your Facebook Page.</div>

      <div class="text-center w-full mb-6">Click on connect button below to connect your instagram account.</div>

      <div class="text-center w-full mt-10 mb-6">
        <button type="button" class="flex rounded-md bg-pink-600 px-3.5 py-1 text-sm text-white mx-auto" @click="onStartIntegration">
          <i class="fa-brands fa-instagram mr-2 text-lg"></i>
          <div class="leading-7">Connect Instagram Account</div>
        </button>
      </div>
      <div class="text-xs text-gray-500 text-center w-full mb-6">
        By signing in, we only get access to your Page's messages. <br />
        Your private messages can never be accessed by Growlytics.
      </div>
    </div>

    <!-- Success -->
    <div v-else>
      <div class="w-full mt-10 mb-10 text-center">
        <img class="w-24 rounded-md mx-auto" src="@/assets/images/messaging-channels/insta.png" />
        <div class="text-xl font-semibold mt-5">Select Instagram Accounts To Setup.</div>
      </div>

      <!-- <div class="text-center mx-auto mt-60 text-gray-600 mb-6 text-xl">Instagram Accounts Linked:</div> -->

      <!-- Iterate All the instagram channels integrated -->
      <div v-for="(account, index) in accountsLinked" :key="index" style="width: 700px" class="mx-auto mb-5 border bg-gray-50 relative overflow-hidden shadow-sm rounded-xl flex justify-between px-4 py-5 cursor-pointer hover:bg-gray-100" @click="toggleAccountSync(index)">
        <div class="flex min-w-0 gap-x-4 w-full">
          <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="account.instagramImageUrl" alt="" />
          <div class="flex-1">
            <div class="pt-0 text-lg font-semibold leading-6 text-gray-900">
              {{ account.instagramUsername }}
            </div>
            <p class="mt-0.5 flex text-xs leading-5 text-gray-500">Facebook Page: {{ account.pageName }}</p>
            <p class="mt-0.5 flex text-xs leading-5 text-gray-500">Approved By: {{ account.fbAccountName }}</p>
          </div>

          <div v-if="account.installed">
            <div class="text-center bg-green-500 text-white px-2 py-1 text-xs rounded-full">Already Installed</div>
          </div>
          <div class="flex pr-2" v-else>
            <i class="fa-sharp fa-solid fa-circle-check text-2xl text-primary-600 cursor-pointer" v-if="account.doInstall == true"></i>
            <i class="fa-sharp fa-solid fa-circle text-2xl text-gray-500 cursor-pointer" v-if="account.doInstall != true"></i>
          </div>
        </div>
        <div class="flex shrink-0 items-center gap-x-4">
          <div class="text-center">
            <!-- <div class="mx-auto text-xs leading-5 text-gray-500">Integration Status</div> -->
            <div v-if="account.status == 'success'" class="max-auto bg-green-600 text-white text-sm rounded-full py-1 px-4">Account Linked Successfully</div>
            <div v-if="account.status == 'duplicate'" class="max-auto bg-yellow-600 text-white text-sm rounded-full py-1 px-4">Account is already linked</div>
          </div>
        </div>
      </div>

      <!-- Continue Button - Redirect to channel list page. -->
      <div class="mt-10 mb-10 mx-auto w-full text-center">
        <el-button type="primary" @click="saveInstagramChannels"> Finish Setup </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import chatSettingsService from '../../../../services/chatSettingsService';
const { chatServiceApiServerlessUrl } = require('@/services/settings.js');
export default {
  data() {
    return {
      isSuccessMode: false,
      inProgress: false,
      popupVisible: false,

      accountsLinked: []
    };
  },
  name: 'InstagramIntegrationOauthHandler',
  methods: {
    showDialog() {
      this.popupVisible = true;
      this.inProgress = false;
      this.isSuccessMode = false;
      this.accountsLinked = [];
    },

    onStartIntegration() {
      let authToken = window.localStorage.getItem('grw-token');

      let oauthUrl = chatServiceApiServerlessUrl + '/settings/instagram-install?growlyticsToken=' + encodeURIComponent(authToken);

      let height = 560;
      let popupWidth = 560;
      let screenWidth = parseInt(window.screen['width']);
      let left = (screenWidth - popupWidth) / 2;
      let top = (window.screen.height - height - 100) / 2;
      let newwindow = window.open(oauthUrl, 'instagram', 'height=' + height + ', width=' + popupWidth + ', top=' + top + ',left=' + left + '  resizable=yes, scrollbars=yes, toolbar=0, menubar=0');
      // let newwindow = window.open(oauthUrl, 'instagram', 'width=560,height=700,toolbar=0,menubar=0');
      if (window.focus) {
        newwindow.focus();
      }
      window.addEventListener('message', (event) => {
        if (event.data && event.data.growlytics && event.data.action == 'instagram-oauth-response') {
          this.handleOauthResponse(event.data.data);
        }
      });
    },

    async handleOauthResponse(queryParams) {
      // Ignore repeat calls
      let code = queryParams.code;
      if (this.postInstallOAuthCode == code) {
        return;
      }
      this.postInstallOAuthCode = code;

      this.inProgress = true;
      let response = await chatSettingsService.instagram.handleInstagramOAuthResponse(queryParams);
      this.accountsLinked = response.data;

      for (let i = 0; i < this.accountsLinked.length; i++) {
        let account = this.accountsLinked[i];
        account.doInstall = account.installed;

        // Keep atleast 1 selected.
        if (i == 0) {
          account.doInstall = true;
        }
      }
      console.log('Instagram accounts linked', this.accountsLinked);
      this.isSuccessMode = true;
      this.inProgress = false;
    },

    async saveInstagramChannels() {
      try {
        let channelsToSync = [];
        for (let i = 0; i < this.accountsLinked.length; i++) {
          let account = this.accountsLinked[i];
          if (account.doInstall) {
            channelsToSync.push(account.meta);
          }
        }
        await chatSettingsService.instagram.saveInstagramChannels(channelsToSync);

        this.successToast('Instagram channel setup successful.');

        // Redirect to channel list page.
        this.$router.push('/settings/messaging-channels');
      } catch (err) {
        console.error('Failed to setup instagram channels', err);
        this.reportError(err);
        this.errorToast('Failed to setup instagram channels. Please contact support.');
      }
    },

    toggleAccountSync(i) {
      this.accountsLinked[i].doInstall = !this.accountsLinked[i].doInstall;
      this.$forceUpdate();
    }
  },

  async mounted() {}
};
</script>

<style></style>
