<template>
  <el-dialog append-to-body custom-class="" title="Add WhatsApp Inbox Channel" :visible.sync="dialogVisible" width="800px">
    <template v-if="selectedVendor == null">
      <div class="p-5 text-gray-900 text-base">Select WhatsApp Vendor</div>

      <!-- List Of Vendors -->
      <div class="mx-5 grid grid-cols-3 gap-4 mb-10">
        <div class="border rounded-lg p-4 pt-5 text-center cursor-pointer hover:bg-gray-50 relative" v-for="(vendor, index) in vendors" :key="index" @click="onVendorSelect(vendor)">
          <img :src="vendor.image" class="h-12 mx-auto" />
          <div class="mt-4 font-medium text-gray-700">{{ vendor.name }}</div>
        </div>
      </div>
    </template>

    <!-- Whats Cloud Api -->
    <div class="p-5" v-if="selectedVendor == 'whatsapp_cloud_api'">
      <div class="text-gray-900 text-base mb-4">WhatsApp Cloud API</div>

      <div v-if="whatsAppCloudApiErrorResponse" class="bg-red-600 tex-xs text-white py-2 px-2 rounded mb-4"><i class="fa-regular fa-circle-exclamation"></i>&nbsp;&nbsp;{{ whatsAppCloudApiErrorResponse }}</div>

      <el-form class="conversionDeadline" label-position="top" :rules="whatsAppCloudApiFormRules" :model="whatsAppCloudApiForm" ref="whatsAppCloudApiForm" @submit="onIntegrateWhatsAppCloudApi">
        <el-form-item label="WhatsApp Number (Mobile Number Associated With Account)" prop="number">
          <el-input v-model="whatsAppCloudApiForm.number" placeholder="+<country_code><number> Example: +919876543210"></el-input>
        </el-form-item>
        <el-form-item label="Facebook Business Account ID" prop="fbBusinessAccountId">
          <el-input v-model="whatsAppCloudApiForm.fbBusinessAccountId" placeholder="Enter Facebook Business Account ID"></el-input>
        </el-form-item>

        <el-form-item label="Facebook Access Token" prop="token">
          <el-input v-model="whatsAppCloudApiForm.token" placeholder="Enter Facebook Access Token"></el-input>
        </el-form-item>

        <el-form-item label="WhatsApp Number ID" prop="numberId">
          <el-input v-model="whatsAppCloudApiForm.numberId" placeholder="Enter WhatsApp Number ID"></el-input>
        </el-form-item>

        <div class="pt-2">
          <el-button type="primary" size="small" :loading="whatsAppCloudApiSaving" @click="onIntegrateWhatsAppCloudApi"> Start Integration</el-button>
        </div>
      </el-form>
    </div>

    <div class="py-20 px-5 w-full text-center text-lg text-black" v-else-if="['whatsapp_dialog360', 'whatsapp_gupshup'].indexOf(selectedVendor) >= 0">Please contact Growlytics support team to setup this WhatsApp vendor.</div>
  </el-dialog>
</template>

<script>
import chatSettingsService from '../../../../../services/chatSettingsService';
export default {
  components: {},
  data() {
    return {
      dialogVisible: true,
      selectedVendor: 'whatsapp_cloud_api',
      vendors: [
        {
          id: 'whatsapp_cloud_api',
          image: require('@/assets/images/settings/whatsapp-vendors/whatsapp-cloud-api.png'),
          name: 'Facebook WhatsApp Cloud'
        },
        {
          id: 'whatsapp_dialog360',
          image: require('@/assets/images/settings/whatsapp-vendors/whatsapp-360dialog.png'),
          name: '360 Dialog'
        },
        {
          id: 'whatsapp_gupshup',
          image: require('@/assets/images/settings/whatsapp-vendors/whatsapp-gupshup.png'),
          name: 'Gupshup Enterprise'
        }
      ],

      whatsAppCloudApiSaving: false,
      whatsAppCloudApiForm: null,
      whatsAppCloudApiFormTemplate: {
        number: '',
        numberId: '',
        token: '',
        fbBusinessAccountId: ''
      },
      whatsAppCloudApiFormRules: {
        number: [{ required: true, message: 'Please enter WhatsApp Mobile Number', trigger: 'blur' }],
        numberId: [{ required: true, message: 'Please enter WhatsApp Number ID', trigger: 'blur' }],
        token: [{ required: true, message: 'Please enter Facebook Access Token', trigger: 'blur' }],
        fbBusinessAccountId: [{ required: true, message: 'Please enter Facebook Business Account ID', trigger: 'blur' }]
      },
      whatsAppCloudApiErrorResponse: 'Inbox with this whatsapp details has already been setup.'
    };
  },

  methods: {
    showDialog() {
      this.dialogVisible = true;
      this.selectedVendor = false;
      this.selectedVendor = null;

      this.whatsAppCloudApiForm = JSON.parse(JSON.stringify(this.whatsAppCloudApiFormTemplate));
      this.whatsAppCloudApiSaving = false;
      this.whatsAppCloudApiErrorResponse = null;
    },

    onVendorSelect(vendor) {
      this.selectedVendor = vendor.id;
    },

    async validateWhatsAppCloudApiForm() {
      return new Promise((resolve) => {
        this.$refs.whatsAppCloudApiForm.validate((valid) => {
          if (valid) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },

    async onIntegrateWhatsAppCloudApi() {
      try {
        // Validate Form.
        let isFormValid = await this.validateWhatsAppCloudApiForm();
        if (!isFormValid) return;

        // Start Integration With Api.
        this.whatsAppCloudApiErrorResponse = null;
        this.whatsAppCloudApiSaving = true;

        let response = await chatSettingsService.whatsApp.integrateWhatsAppCloudApiChannel(this.whatsAppCloudApiForm);

        if (response.data.success) {
          this.successToast('WhatsApp inbox channel added successfully.');
          // Redirect to channel list page
          this.$router.push('/settings/messaging-channels');
          return;
        }

        this.whatsAppCloudApiErrorResponse = response.data.message;
      } catch (err) {
        console.error('Failed to setup whatsapp channel', err);
        this.reportError(err);
        this.errorToast('Failed to setup whatsapp channel. Please contact support.');
      } finally {
        this.whatsAppCloudApiSaving = false;
      }
    }
  }
};
</script>

<style></style>
